* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background: white;
}

body {
  font-family: 'Times New Roman', sans-serif;
  overflow-x: hidden;
}

p,
span {
  font-family: 'Times New Roman';
  font-size: 1.5rem;
}

h3 {
  font-size: 2rem;
}

a:focus,
button:focus {
  outline: none;
}
